





















import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';

@Component
export default class Tags extends Vue {
  selectedTag={id: '',name: ''};
  get labelType(){
    return this.$store.state.type
  }
  get labelSelect(){
    return "dark"
  }
  get labelList(){
    return this.$store.state.labelList.filter((item:any)=>item.type===this.labelType)
  }
  created():void{
    this.$store.commit('fetchLabel')
    this.toggle(this.labelList[0])
  }

  toggle(tag: {id: '',name: ''}):void {
    this.selectedTag=tag
    this.$emit('update:value',this.selectedTag)
  }
  modifyLabel():void {
    this.$emit('update:draw')
  }
  @Watch('labelType')
  changeCurrentTag(){
    this.toggle(this.labelList[0])
  }
}
