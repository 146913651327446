










export default {
  name: "NotFound"
}
