











import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

type Tab = { text: string, type: string }
@Component
export default class Tags extends Vue {

  @Prop({required: true}) tabList!: Tab[];
  @Prop() value!: string;
  @Prop() classPrefix?: string;

  selectType(type: string) {
    this.$emit('update:value', type);
  }
  created(){
    this.$store.state.type=this.tabList[0].type
  }
}
