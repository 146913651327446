import Vue from 'vue';
import Vuex from 'vuex';
import createId from '@/lib/createId';
import clone from '@/lib/clone';


Vue.use(Vuex);


type State = {
    labelList: Label[]
    currentLabel?: { id: string, name: string }
    recordList: RecordItem[],
    type:string,
    labelStatus:string,
    drawerVisible:boolean
}
const store = new Vuex.Store({
    state: {
        labelList: [],
        currentLabel: undefined,
        recordList: [],
        labelStatus:'',
        type:'-',
        drawerVisible:false
    } as State,
    mutations: {
        fetchRecordList(state) {
            state.recordList = JSON.parse(window.localStorage.getItem('recordList') || '[]') as RecordItem[];
        },
        fetchLabel(state) {
            state.labelList = JSON.parse(window.localStorage.getItem('labelList') || '[]');
            if(state.labelList.length<1){
                state.labelList=[
                    {id: '01', name: '餐饮',type:'-',icon:'🍱' },
                    {id: '02', name: '交通',type:'-',icon:'🚕' },
                    {id: '03', name: '服饰',type:'-',icon:'👖' },
                    {id: '04', name: '购物',type:'-',icon:'🛍' },
                    {id: '05', name: '‍️服务',type:'-',icon:'💆'},
                {id: '06', name: '教育',type:'-',icon:'📚'},
                {id: '07', name: '娱乐',type:'-',icon:'🎠'},
                {id: '08', name: '运动',type:'-',icon:'🏋️'},
                {id: '10', name: '缴费',type:'-',icon:'💳'},
                {id: '11', name: '旅行',type:'-',icon:'🛫'},
                {id: '12', name: '宠物',type:'-',icon:'🙉'},
                {id: '13', name: '医疗',type:'-',icon:'🏥'},
                {id: '14', name: '礼物',type:'-',icon:'🎁'},
                {id: '15', name: '工资',type:'+',icon:'💰'},
                {id: '16', name: '奖金',type:'+',icon:'🤑'},
                {id: '17', name: '收红包',type:'+',icon:'🧧'},
                {id: '18', name: '退款',type:'+',icon:'💲'},
                {id: '19', name: '转账',type:'+',icon:'💳'},
                {id: '20', name: '其他',type:'+',icon:'🗂'},

                ]
            }
        },
        createTag(state, tagName) {
            const tagNames = state.labelList.map(item => item.name);
            if (tagNames.indexOf(tagName) >= 0) {
               state.labelStatus='fail'
            } else {
                const id = createId().toString();
                state.labelList.push({id: id, name: tagName,type: state.type,icon:'📝'});
                store.commit('saveLabel');
                state.labelStatus='success'
            }
        },
        setCurrentLabel(state, id) {
            const label = state.labelList.filter(item => {
                return item.id === id;
            })[0];
            if (label) {
                state.currentLabel = label;
            }
        },
        saveLabel(state) {
            window.localStorage.setItem('labelList', JSON.stringify(state.labelList));
        },
        updateLabel(state, payload: { id: string, name: string }) {
            const {id, name} = payload;
            const idList = state.labelList.map(item => {
                return item.id;
            });
            if (idList.indexOf(id) >= 0) {
                const names = state.labelList.map(item => item.name);
                if (names.indexOf(name) >= 0) {
                    state.labelStatus='fail'
                } else {
                    const tag = state.labelList.filter(item => item.id === id)[0];
                    tag.name = name;
                    store.commit('saveLabel');
                    state.labelStatus='success'
                }
            } else {
                return 'notfound';
            }
        },
        removeLabel(state, id) {

            for (let i = 0; i < state.labelList.length; i++) {
                if (state.labelList[i].id === id) {
                    state.labelList.splice(i, 1);
                    store.commit('saveLabel');
                }
            }
            Vue.prototype.$message({
                message: '删除成功',
                type: 'success'
            });
        },
        createRecord(state, record) {
            const record2: RecordItem = clone(record);
            record2.createTime = new Date();
            state.recordList.push(record2);
        },
        deleteRecord(state,record){
            state.recordList.forEach((item,index)=>{
                if(item.createTime===record.createTime){
                    state.recordList.splice(index, 1);
                }
            })
            store.commit('saveRecord');

        },
        saveRecord(state) {
            window.localStorage.setItem('recordList', JSON.stringify(state.recordList));
        },
        changeDrawerVisible(state){
           state.drawerVisible=!state.drawerVisible
        }
    },
    actions: {},
    getters: {}
});
export default store;
