







import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import * as echarts from 'echarts';

@Component
export default class Charts extends Vue {
  @Prop() chartData!: string;
  // chartData:{value:number,name:string}[]=[]
  chart: any = {};

  get records() {
    return this.$store.state.recordList;
  }

  mounted() {
    // 基于准备好的dom，初始化echarts实例
    let myChart = echarts.init(this.$refs.chart as HTMLElement);
    this.chart = myChart;
    // 指定图表的配置项和数据
    this.updateChart();

  }

  updateChart() {
    let option = {
      title: {
        text: '',
        subtext: '',
        left: 'center'
      },
      tooltip: {
        trigger: 'item'
      },

      series: [
        {
          name: '',
          type: 'pie',
          radius: '65%',
          data: this.chartData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10, shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
    // 使用刚指定的配置项和数据显示图表。
    this.chart.setOption(option);
  }

  @Watch('chartData')
  onDataUpdate(){
    this.updateChart()
  }
}
