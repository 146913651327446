










import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';

@Component
export default class FormItem extends Vue {
  @Prop() fieldName!: string;
  @Prop() placeHoder!: string;
  $bus: any;
  value='';
  onChildChanged(value: string) {

    this.$emit('update:value', value);
  }
  beforeCreate() {
   this.$bus.$on("clear",()=>{
     this.value=''
   })
  }
}

