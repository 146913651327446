








import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
export default class Drawer extends Vue {
  @Prop() show!: boolean;
  @Prop() size!: string;
  handleClose() {
    this.$emit('update:value', false);
  }

}
