import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Nav from "@/components/Nav.vue";
import Layout from "@/components/Layout.vue";
import Icon from "@/components/Icon.vue";

Vue.config.productionTip = false
// 引入elementUI
import { Tag,Tabs,TabPane,Drawer,MessageBox ,Message,Button,Input,DatePicker} from 'element-ui';
Vue.use(Tag);
Vue.use(Drawer);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Button);
Vue.use(Input);
Vue.use(DatePicker);
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;




//引入全局Nav
Vue.component('Nav',Nav)
//引入Layout
Vue.component('Layout',Layout)
//引入icon
Vue.component('Icon',Icon)

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus=this
  }
}).$mount('#app')
