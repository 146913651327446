











































































import Vue from 'vue';

import {Component, Prop} from 'vue-property-decorator';
import Drawer from '@/components/Money/Drawer.vue';


@Component({
  components: {Drawer}
})
export default class TabDrawer extends Vue {
  @Prop() showDrawer!: boolean;

  drawEditVisible = false;
  drawConfirmVisible = false;
  drawLabelEditVisible = false;
  addLabelEditVisible=false
  tagName=''

  get currentLabel() {
    return this.$store.state.currentLabel;
  }

  handleClose() {
    this.$emit('update:value')
  }

  get labels() {
    return this.$store.state.labelList.filter((item:any)=>item.type===this.$store.state.type);
  }

  intoEditLabel() {
    this.drawLabelEditVisible = true;
    this.drawEditVisible = false;
    this.tagName=this.currentLabel.name
  }

  editLabel(id: string) {
    this.drawEditVisible = true;
    this.$store.commit('setCurrentLabel', id);
  }

  deleteLabelConfirm() {
    this.drawConfirmVisible = true;
    this.drawEditVisible = false;
  }

  deleteLabel() {

    if (this.currentLabel) {
      this.$store.commit('removeLabel', this.currentLabel.id);
    }
    this.drawConfirmVisible = false;

  }
  updateTag(){
    if(this.currentLabel){
      this.$store.commit('updateLabel',{id:this.currentLabel.id,name:this.tagName})
    }
    if(this.$store.state.labelStatus==='fail'){
      this.$message({
        showClose: true,
        message: '标签名已存在',
        type: 'error'
      });
    }
    else {
      this.closeDrawer()
    }

  }
  closeDrawer() {
    this.drawConfirmVisible = false;
    this.drawLabelEditVisible = false;
    this.addLabelEditVisible = false;

  }
  createTag(){
    if(this.tagName){
      this.$store.commit('createTag',this.tagName)
    }
    if(this.$store.state.labelStatus==='fail'){
      this.$message({
        showClose: true,
        message: '标签名已存在',
        type: 'error'
      });
    }
    else {
      this.closeDrawer()
    }
  }
  intoAddLabelDrawer(){
    this.tagName=''
    this.addLabelEditVisible=true
  }
}
