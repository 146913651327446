












































import Vue from 'vue';
import {Component, Watch} from 'vue-property-decorator';
import Tabs from '@/components/Tabs.vue';
import tabList from '@/components/consts/tabList';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import clone from '@/lib/clone';
import Charts from '@/components/Charts.vue';
import formatTime from '@/lib/formatTime';
import WhiteSpace from '@/components/WhiteSpace.vue';

dayjs.extend(utc);
@Component({
  components: {WhiteSpace, Charts, Tabs}
})
export default class Statistics extends Vue {
  recordType = '-';
  moneyOut = '0';
  moneyIn = '0';
  tabList = tabList;
  month = '';

  get recordList() {
    return this.$store.state.recordList;
  }


  get group() {
    let recordHash: { [key: string]: RecordItem[] } = {};
    let records = clone(this.recordList).filter((item: { type: string; }) => {
      return item.type === this.recordType;
    });
    let monthNow = this.month;
    if (monthNow === '') {
      monthNow = dayjs().format('YYYY-M');
    }
    records = records.filter((item: any) => {
      return dayjs(item.createTime).format('YYYY-M') === monthNow;
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    records.sort((item1, item2) => {
      return dayjs(item2.createTime).unix() - dayjs(item1.createTime).unix();
    });
    for (let i = 0; i < records.length; i++) {
      const time = dayjs(records[i].createTime).utc().local().format('YYYY-M-D');
      recordHash[time] = recordHash[time] || [];
      recordHash[time].push(records[i]);
    }
    type RecordList = { timeGroup: string, value: RecordItem[], total: number }
    let recordList: RecordList[] = [];
    let today = formatTime('YYYY-M-D');
    for (let k in recordHash) {
      let dayFormat = k;
      if (k === today) {
        dayFormat = '今天';
      }
      recordList.push({timeGroup: dayFormat, value: recordHash[k], total: 0});
    }

    recordList.forEach((group) => {
      group.total = group.value.reduce((total, item) => {
        return total + item.amount;
      }, 0);
    });
    recordList.sort((item1, item2) => {
      return dayjs(item2.timeGroup).unix() - dayjs(item1.timeGroup).unix();
    });

    return recordList;
  }

  created() {
    this.$store.commit('fetchRecordList');

  }

  get chartData() {
    let hashGroup: { [name: string]: number } = {};
    let records = clone(this.recordList).filter((item: RecordItem) => {
      return item.type === this.recordType;
    });
    let monthNow = this.month;
    if (monthNow === '') {
      monthNow = dayjs().format('YYYY-M');
    }
    records = records.filter((item: any) => {
      return dayjs(item.createTime).format('YYYY-M') === monthNow;
    });
    const selectTime = monthNow;
    for (let i = 0; i < records.length; i++) {
      if (formatTime('YYYY-M', records[i].createTime) === selectTime) {
        let name = records[i].label.name;
        hashGroup[name] = hashGroup[name] || 0;
        hashGroup[name] += records[i].amount;
      }
    }

    let data = [];
    for (let k in hashGroup) {
      data.push({value: hashGroup[k], name: k});
    }

    return data;
  }

  computeMoney(type: string) {
    let total = 0;
    let list = this.recordList.filter((item: { type: string; }) => {
      return item.type === type;
    });
    list.forEach((item: { amount: number; createTime: string }) => {
      let recordTime = formatTime('YYYY-M', item.createTime);
      let today = formatTime('YYYY-M');
      if (recordTime === today) {
        total += item.amount;
      }
    });
    let totalFix = total.toFixed(1);
    return totalFix;

  }

  uploadTime() {
    console.log('hell');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.tiemPick.focus();

  }
  deleteRecord(record: any){
    this.$store.commit('deleteRecord',record)
  }
  @Watch('group')
  onRecordChange() {

    this.moneyIn = this.computeMoney('+');
    this.moneyOut = this.computeMoney('-');
  }
}
