






//将目录中的svg文件全部引用
// eslint-disable-next-line no-undef
let importAll = (requireContext:__WebpackModuleApi.RequireContext)=>requireContext.keys().forEach(requireContext);try {importAll(require.context('../assets/icons',true,/\.svg$/));}catch (error) {console.log(error);}

export default {
  name: "Icon",
  props:['name']
}
