<template>
  <div class="app">
    <div class="mask" @click.self="close" v-show="qrVisible">
      <div>
        <div class="text">手机扫描二维码获得最佳体验 <span @click="close">X</span></div>
        <img src="../src/assets/qrcode.png">
      </div>
    </div>
    <router-view/>
  </div>
</template>

<style lang="scss">

@import "~@/assets/style/helper.scss";
@import "~@/assets/style/reset.scss";
.el-message{
  min-width: 50vh;
}
body{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
}
</style>
<script>
export default {
  data() {
    return {
      qrVisible: true
    }
  },
  components: {},
  created() {
    // 禁止页面缩放
    window.onload = function () {
      document.addEventListener("touchstart", function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (event) {
        event.preventDefault();
      });
    };
    document.documentElement.addEventListener(
        "touchmove",
        function (event) {
          if (event.touches.length > 1) {
            event.preventDefault();
          }
        },
        false
    );

    if(window.outerWidth<500){
      this.close()
    }
  }
,methods:{
    close(){

    this.qrVisible=false
    }
  }

}

</script>

<style lang="scss" scoped>
.app{
  max-width: 500px;
  margin: 0 auto;

}
.mask{
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(196, 196, 196, 0.8);;
  display: flex;
  flex-direction: column;
  align-items: center;
  > div{
    .text{
      cursor: default;
      color: black;
      background-color: white;
      text-align: center;
      padding-top: 10px;
      position: relative;
      span{
        cursor: pointer;
        position: absolute;
        right: 10px;
      }
    }
    margin: auto 0;
  }

}
</style>
