<template>
  <div class="layoutWrapper" :style="`height: ${viewHeight}`">
    <div class="content" :class="contentClass">
      <slot></slot>
    </div>
    <Nav/>
  </div>
</template>

<script>
export default {
  name: "Layout",
  props:['contentClass'],
  data() {
    return {
      viewHeight: ''
    };
  },
  created() {
    this.viewHeight=window.innerHeight+'px'
  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {display: none; /* Chrome Safari */}
.layoutWrapper {

  display: flex;
  flex-direction: column;

}

.content {
  overflow: scroll;

  flex-grow: 1;
}
</style>
