<template>
  <nav>
    <router-link to="/Money" class="item" active-class="selected">
      <icon name="money"/>
      速记
    </router-link>
    <router-link to="/Statistics" class="item" active-class="selected">
      <icon name="statistics"/>
      统计
    </router-link>
    <div class="item" @click="openTagDrawer">
      <icon name="label"/>
      标签
    </div>
  </nav>
</template>

<script lang="js">


export default {
  name: "Nav",
  methods:{
    openTagDrawer(){
      this.$router.push('/Money')
      this.$store.commit('changeDrawerVisible')
    }
  }

}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/helper.scss";
a{
  text-decoration: none;
}
nav{

  box-shadow: -5px 1px 5px #d3d1d1;
  display: flex;
  justify-content: space-around;
  >.item{

    padding: 2px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .icon{
    height: 32px;
    width: 32px;
  }
  >.selected{
    color: $color-highlight;
  }
}
</style>
