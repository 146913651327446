























import Vue from 'vue';
import {Component, Watch} from 'vue-property-decorator';
import FormItem from '@/components/Money/FormItem.vue';
import NumberPad from '@/components/Money/NumberPad.vue';
import Tags from '@/components/Money/Labels.vue';
import tabList from '@/components/consts/tabList';
import Tabs from '@/components/Tabs.vue';
import TabDrawer from '@/components/Money/LabelDrawer.vue';
import formatTime from '@/lib/formatTime';
import {mapState} from 'vuex';


@Component({
  components: {TabDrawer, Tabs, Tags, NumberPad,  FormItem},
  computed:mapState(['drawerVisible'])
})

export default class Money extends Vue {
  tabList = tabList
  drawerShow=false
  moneyOut='0'
  moneyIn='0'
  get recordList(){
    return this.$store.state.recordList
  }

  created(){
    this.$store.commit('fetchRecordList')
  }
  record:RecordItem={
    label: {id: '',name: '',type:'',icon:''},note:'',type:'-',amount:0
  }

  onUpdateNote(value:string){
    this.record.note=value
  }
  onUpdateAmount(value:string){
    this.record.amount=parseFloat(value)
  }

  saveRecord(){

    this.$store.commit('createRecord',this.record)
    this.onUpdateNote('')
  }
  onUpdateTag(tags:{ id: string,name: string,type:string ,icon:string}){
    this.record.label=tags||['']
  }
  showDraw(){
    this.$store.commit('changeDrawerVisible')
  }
  computeMoney(type:string){
    let total=0
    let list=this.recordList.filter((item: { type: string; }) =>{
      return item.type===type
    })
    list.forEach((item: { amount: number; createTime:string})=>{
      let recordTime=formatTime('YYYY-M-D',item.createTime)
      let today=formatTime('YYYY-M-D')
      if(recordTime===today)
      {
        total+=item.amount
      }
    })
    let totalFix=total.toFixed(1)
    return totalFix
  }

  changeType(type:string){

    this.$store.state.type=type
  }
  @Watch('recordList')
  onRecordListChange(){

    this.$store.commit('saveRecord')
    this.moneyIn=this.computeMoney('+')
    this.moneyOut=this.computeMoney('-')
  }
}

