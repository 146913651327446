

























import Vue from 'vue';
import {Component} from 'vue-property-decorator';

@Component
export default class NumberPad extends Vue {
  output = '0';
  $bus: any;

  inputContent(event: MouseEvent) {
    const button = event.target as HTMLButtonElement;
    const input = button.textContent as string;
    let temp=this.output
    temp += input;
    if(parseInt(temp)>=1000000){

      this.$message({
        message: '金额不能大于1,000,000',
        type: 'warning',
        center: true
      });
      return
    }

    if (this.output === '0') {
      if ('0123456789'.indexOf(input) >= 0) {
        this.output = input;
      } else {
        this.output += input;
      }
      return;
    }
    if (this.output.indexOf('.') >= 0 && input === '.') {
      return;
    }
    if (this.output.indexOf('.') >= 0 ) {
      let tail=this.output.split('.')[1].length
      if(tail===1){
        return;
      }
    }
    this.output += input;

  }

  deleteContent() {
    this.output = this.output.substr(0, this.output.length - 1);
    if (this.output.length === 0) {
      this.output = '0';
    }
  }

  clear() {
    this.output = '0';
  }

  pressOk() {
    this.$bus.$emit("clear");
    if(parseFloat(this.output)>0.1){

      this.$emit('update:value', this.output);
      this.$emit('submit', this.output);
      this.output='0'
    }else {
      this.output='0'
    }

  }
}
